import React from "react";
import styled from "styled-components";
import Paragraph from "../components/blog/Paragraph";
import BlogTitle from "../components/blog/BlogTitle";
import ImageWithCaption from "../components/blog/ImageWithCaption";
import BlogHeading2 from "../components/blog/BlogHeading2";
import InternalLink from "../components/blog/InternalLink";
import ExternalLink from "../components/blog/ExternalLink";
import BlockQuote from "../components/blog/BlockQuote";
import UnorderedList from "../components/blog/UnorderedList";
import UnorderedListItem from "../components/blog/UnorderedListItem";
import {StaticImage} from "gatsby-plugin-image";
import SEO from "../components/SEO";
import BlogHeading3 from "../components/blog/BlogHeading3";

const BlogPostWrapper = styled.main`
  padding: var(--main-padding-mob);
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledMainContent = styled.div`  
  max-width: 750px; 
`;

const HeroSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const BlogPost = ({location}) =>{
    return <BlogPostWrapper> <StyledMainContent>
        <SEO
            title="Vegan Pantry Staples: Kickstart Your Vegan Journey"
            description="Whether you're a baby vegan or just want to lower your animal product consumption, you could benefit from this list of vegan pantry staples."
            location={location}
            pageType="article"
        />

        <HeroSection>
            <BlogTitle>
                Vegan Pantry Staples: Kickstart Your Vegan Journey
            </BlogTitle>
            <ImageWithCaption>
                <StaticImage src="../assets/images/blogs/vegan-pantry-staples-kickstart-your-vegan-journey.jpg" alt="Vegan Pantry Staples: Kickstart Your Vegan Journey"/>
                <em>Photo by <ExternalLink to="https://unsplash.com/@ellaolsson?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Ella Olsson</ExternalLink> on <ExternalLink to="https://unsplash.com/s/photos/vegan?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</ExternalLink></em>
            </ImageWithCaption>
        </HeroSection>
        <Paragraph>
            Veganism has taken the world by storm in the last decade, and it is proving to be more than just a temporary fad. Vegan populations in countries around the world are growing year by year. Examples include:
        </Paragraph>
        <UnorderedList>
            <UnorderedListItem><ExternalLink to= "https://viva.org.uk/lifestyle/statistics-about-veganism/">2.1% of Canadians</ExternalLink> consider themselves vegan.</UnorderedListItem>
            <UnorderedListItem>Up to 1% of the German population is on a vegan diet.</UnorderedListItem>
            <UnorderedListItem>Israel has the highest percentage of people leading a vegan lifestyle, at 5% of the total population.</UnorderedListItem>
            <UnorderedListItem>Between 2011 and 2016, the Italian vegan population grew by 94.4%. </UnorderedListItem>
            <UnorderedListItem>In the last three years in the United States, there was a staggering <ExternalLink to = "https://www.reportbuyer.com/product/4959853/top-trends-in-prepared-foods-exploring-trends-in-meat-fish-and-seafood-pasta-noodles-and-rice-prepared-meals-savory-deli-food-soup-and-meat-substitutes.html">600%</ExternalLink>  increase in the number of people who identify as vegan.</UnorderedListItem>
        </UnorderedList>
        <Paragraph>
            The benefits of veganism and vegetarianism range from better physical health to slowing down climate change to ensuring better animal welfare. Even if you don't intend to completely give up meat or animal products in the near future, reducing the amount of meat you consume on a daily basis could still have drastic positive impacts on all three of these aspects.
        </Paragraph>
        <Paragraph>
            So, regardless of whether you're a baby vegan or just want to know how to lower your meat or animal product consumption, you could greatly benefit from a <InternalLink to = "/pantry-staples-list/">list of pantry staples </InternalLink>that every vegan should have.
        </Paragraph>
        <Paragraph>
            Here's what you need to kickstart your vegan journey, in alphabetical order.
        </Paragraph>
        <BlogHeading2>
            Baking essentials
        </BlogHeading2>
        <Paragraph>
            Alphabetically, baking essentials are the first in line. However, we would likely put them first even if this list wasn't following the alphabet, just because, emotionally, baked goods are crucial to one's survival in this world.
        </Paragraph>
        <Paragraph>
            No pantry is complete without a set of baking ingredients that help you whip up a batch of muffins, brownies, or banana bread whenever the craving hits.
        </Paragraph>
        <Paragraph>
            Here are vegan baking essentials you should keep in your kitchen pantry:
        </Paragraph>
        <UnorderedList>
            <UnorderedListItem>Flour (all-purpose wheat flour, spelt flour, oat flour, almond flour, brown rice flour, or any kind you prefer)</UnorderedListItem>
            <UnorderedListItem>Vanilla extract</UnorderedListItem>
            <UnorderedListItem>Dairy-free chocolate or chocolate chips</UnorderedListItem>
            <UnorderedListItem>Baking powder</UnorderedListItem>
            <UnorderedListItem>Baking soda</UnorderedListItem>
            <UnorderedListItem>Cornstarch</UnorderedListItem>
            <UnorderedListItem>Unsweetened cocoa powder</UnorderedListItem>
            <UnorderedListItem>A selection of sweeteners (check out the Sweeteners section below)</UnorderedListItem>
        </UnorderedList>
        <BlogHeading2>
            Beans and legumes
        </BlogHeading2>
        <Paragraph>
            There is a reason why beans are the type of food most commonly associated with vegan cooking - they are full of protein, fiber, vitamins (especially vitamin B), and minerals such as iron, potassium, magnesium, zinc, and more. They offer <ExternalLink to = "https://www.webmd.com/diet/ss/slideshow-why-beans-are-good-for-health">loads of health benefits</ExternalLink>, including better blood sugar control, helping you maintain a healthy weight, boosting your heart health, etc.
        </Paragraph>
        <Paragraph>
            Whether dried beans or canned beans, you cannot have a mostly (or completely) plant based diet unless your pantry is stocked with this superfood.
        </Paragraph>
        <Paragraph>
            Here are some bean options for you to consider:
        </Paragraph>
        <UnorderedList>
            <UnorderedListItem>Black beans</UnorderedListItem>
            <UnorderedListItem>Garbanzo beans</UnorderedListItem>
            <UnorderedListItem>White beans</UnorderedListItem>
            <UnorderedListItem>Kidney beans</UnorderedListItem>
            <UnorderedListItem>Navy beans</UnorderedListItem>
            <UnorderedListItem>Pinto beans, etc.</UnorderedListItem>
        </UnorderedList>
        <Paragraph>
        Legumes are a large plant group consisting of plants that produce seeds in a pod. Various foods that you consider vegetables, nuts, or beans are actually legumes. For example:
        </Paragraph>
        <UnorderedList>
            <UnorderedListItem>Peas</UnorderedListItem>
            <UnorderedListItem>Chickpeas</UnorderedListItem>
            <UnorderedListItem>Lentils</UnorderedListItem>
            <UnorderedListItem>Soybeans</UnorderedListItem>
            <UnorderedListItem>Peanuts</UnorderedListItem>
        </UnorderedList>
        <Paragraph>
            Technically, beans are also legumes, despite our somewhat inaccurate heading above.
        </Paragraph>
        <Paragraph>
            In much the same manner as beans, other <ExternalLink to = "https://www.healthline.com/nutrition/legumes-good-or-bad">legumes are rich in protein</ExternalLink>, fiber, iron, potassium, magnesium, zinc, and vitamin B. You are free to choose which ones you want to stock your vegan pantry with if you wish to try them out in your diet.
        </Paragraph>
        <BlockQuote>
            <b>PRO TIP:</b> Add all these ingredients to a <InternalLink to="/#download-section">handy shopping list app</InternalLink> (it’s free!) so you don’t forget to buy them next time you’re at the store.
        </BlockQuote>
        <BlogHeading2>
            Canned goods
        </BlogHeading2>
        <Paragraph>
            Canned, jarred, and bottled goods are an excellent time saver. Instead of washing and dicing tomatoes yourself, you can instead opt for opening a can of diced tomatoes and reduce your meal prep time by potentially dozens of minutes. Same goes for canned beans vs. cooking beans yourself, bottled pasta sauce vs. making the sauce yourself, etc.
        </Paragraph>
        <Paragraph>
            Here is a list of canned, bottled, or jarred vegan pantry ingredients. It is by no means exhaustive, but it should give you some good ideas of what you can stock up on:
        </Paragraph>
        <UnorderedList>
            <UnorderedListItem>Diced tomatoes</UnorderedListItem>
            <UnorderedListItem>Tomato paste</UnorderedListItem>
            <UnorderedListItem>Sun-dried tomatoes</UnorderedListItem>
            <UnorderedListItem>Coconut milk</UnorderedListItem>
            <UnorderedListItem>Pasta sauce</UnorderedListItem>
            <UnorderedListItem>Baked or cooked beans</UnorderedListItem>
            <UnorderedListItem>Olives</UnorderedListItem>
            <UnorderedListItem>Olive oil (especially extra virgin olive oil)</UnorderedListItem>
            <UnorderedListItem>Unsweetened applesauce</UnorderedListItem>
            <UnorderedListItem>Jams (apricot, strawberry, mango, and more)</UnorderedListItem>
            <UnorderedListItem>Salsa</UnorderedListItem>
            <UnorderedListItem>Lemon or lime juice</UnorderedListItem>
            <UnorderedListItem>Soy sauce</UnorderedListItem>
        </UnorderedList>
        <BlogHeading2>
            Grains
        </BlogHeading2>
        <Paragraph>
            Despite what most fitness and nutrition ‘coaches’ on social networks are preaching, <ExternalLink to = "https://www.hsph.harvard.edu/nutritionsource/carbohydrates/">carbohydrates are actually good for you</ExternalLink>. They are an important nutrient group that provides the energy you need to work, enjoy your hobbies, socialize with your loved ones, and have an overall productive and fulfilled day. 
        </Paragraph>
        <Paragraph>
            Grains, and especially whole grains, are an excellent source of carbohydrates.
        </Paragraph>
        <Paragraph>
            Here are vegan pantry essentials from this food group:
        </Paragraph>
        <UnorderedList>
            <UnorderedListItem>Rice</UnorderedListItem>
            <UnorderedListItem>Quinoa</UnorderedListItem>
            <UnorderedListItem>Barley</UnorderedListItem>
            <UnorderedListItem>Couscous</UnorderedListItem>
            <UnorderedListItem>Bulgur</UnorderedListItem>
            <UnorderedListItem>Oats</UnorderedListItem>
        </UnorderedList>
        <Paragraph>
            Even though you might want to emphasize whole grain options such as brown rice, you can also opt for white rice on occasion and in certain dishes.
        </Paragraph>
        <BlogHeading2>
            Nuts
        </BlogHeading2>
        <Paragraph>
            Nuts are loaded with healthy fats as well as a whole <ExternalLink to = "https://www.healthline.com/nutrition/8-benefits-of-nuts">host of other substances</ExternalLink> that contribute to lower cholesterol levels, better heart health, lower blood pressure, reduced inflammation, and similar. Whether you’re indulging in a handful of your favorite nuts as a snack or sprinkling them over your salad or favorite dish, there is no denying that they are a vegan meal staple. 
        </Paragraph>
        <Paragraph>
            Why not try adding some of the following nut types to your homemade vegan pantry:
        </Paragraph>
        <UnorderedList>
            <UnorderedListItem>Walnuts</UnorderedListItem>
            <UnorderedListItem>Almonds</UnorderedListItem>
            <UnorderedListItem>Hazelnuts</UnorderedListItem>
            <UnorderedListItem>Brazil nuts</UnorderedListItem>
            <UnorderedListItem>Pecans</UnorderedListItem>
            <UnorderedListItem>Cashews</UnorderedListItem>
            <UnorderedListItem>Pistachios</UnorderedListItem>
            <UnorderedListItem>Macadamia nuts</UnorderedListItem>
        </UnorderedList>
        <Paragraph>
            Any nut butter type, such as almond butter or peanut butter, if you classify peanuts as nuts rather than legumes, is also an excellent vegan pantry staple.
        </Paragraph>
        <ImageWithCaption>
            <StaticImage src="../assets/images/blogs/2022-11-vegan-pantry-staples-colorful-plate-of-tortillas-veggies-and-beans.png" alt=" Colorful plate of three small tortillas filled with beans and veggies."/>
            <em>Photo by <ExternalLink to="https://unsplash.com/@shanriley?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Shannon Nickerson</ExternalLink> on <ExternalLink to="https://unsplash.com/s/photos/vegan?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</ExternalLink></em>
        </ImageWithCaption>
        <BlogHeading2>
            Pasta
        </BlogHeading2>
        <Paragraph>
            Let’s be real - there is no comfort food without pasta. At the end of a long day, when you are feeling wrung out from your obligations and trips out of the house, what better way is there to treat yourself than a hot pasta dish with your favorite sauce.
        </Paragraph>
        <Paragraph>
            Pasta is also highly versatile - you can make hundreds, if not thousands of different varieties by combining different sauces and protein sources. You can choose grain-free pasta if you wish to up your plant based protein and lower your gluten intake.
        </Paragraph>
        <Paragraph>
            Some pasta options for you to stock up on:
        </Paragraph>
        <UnorderedList>
            <UnorderedListItem>Whole wheat pasta</UnorderedListItem>
            <UnorderedListItem>Brown rice pasta</UnorderedListItem>
            <UnorderedListItem>Quinoa pasta</UnorderedListItem>
            <UnorderedListItem>Chickpea pasta</UnorderedListItem>
            <UnorderedListItem>Black bean pasta</UnorderedListItem>
            <UnorderedListItem>Red lentil pasta</UnorderedListItem>
        </UnorderedList>
        <BlogHeading3>
            Does the pasta shape matter?
        </BlogHeading3>
        <Paragraph>
            Penne taste exactly the same as fusilli, which taste exactly the same as spaghetti, macaroni, and other pasta shapes.
        </Paragraph>
        <Paragraph>
            So what’s the difference, then? Does it matter what pasta shape you choose?
        </Paragraph>
        <Paragraph>
            The truth is that specific pasta shapes are meant for specific types of sauces. For example, if you are making a thicker, robust sauce, you should go for larger pasta shapes. On the other hand, if you are going for a creamier, lighter type of sauce, then a thinner pasta shape such as spaghetti, is ideal. 
        </Paragraph>
        <BlogHeading2>
            Seeds
        </BlogHeading2>
        <Paragraph>
            In any kind of diet, especially one focused on plant based food, seeds are amazing snacks or toppers for salads and baked goods. They are biologically engineered to help grow a new plant life, so <ExternalLink to = "https://health.clevelandclinic.org/the-6-best-seeds-to-eat/">they are packed with</ExternalLink> fiber, healthy fats, and minerals such as iron, magnesium, calcium, and phosphorus. 
        </Paragraph>
        <Paragraph>
            Flaxseeds are especially handy in a vegan kitchen as they represent an awesome replacement to eggs in delicious vegan recipes (in flaxseed meal form and when combined with water).
        </Paragraph>
        <Paragraph>
            Here are some vegan staple seeds to start you off:
        </Paragraph>
        <UnorderedList>
            <UnorderedListItem>Flaxseeds</UnorderedListItem>
            <UnorderedListItem>Sesame seeds</UnorderedListItem>
            <UnorderedListItem>Sunflower seeds</UnorderedListItem>
            <UnorderedListItem>Pumpkin seeds</UnorderedListItem>
            <UnorderedListItem>Chia seeds</UnorderedListItem>
        </UnorderedList>
        <BlogHeading2>
            Spices
        </BlogHeading2>
        <Paragraph>
            No food, vegan or otherwise, would be half as appetizing if it weren’t for the flavor of all the mouthwatering spices the world has to offer.
        </Paragraph>
        <Paragraph>
            First, let’s mention some good ol’ basic spices any pantry should have:
        </Paragraph>
        <UnorderedList>
            <UnorderedListItem>Salt (himalayan salt, smoked salt, sea salt, or any kind you like)</UnorderedListItem>
            <UnorderedListItem>Black pepper</UnorderedListItem>
            <UnorderedListItem>Garlic powder</UnorderedListItem>
            <UnorderedListItem>Chili powder</UnorderedListItem>
            <UnorderedListItem>Red pepper flakes</UnorderedListItem>
            <UnorderedListItem>Cinnamon</UnorderedListItem>
            <UnorderedListItem>Oregano</UnorderedListItem>
        </UnorderedList>
        <Paragraph>
            Once you perfect the use of these must-have spices, you can expand your collection with a wide range of others, depending on your personal taste:
        </Paragraph>
        <UnorderedList>
            <UnorderedListItem>Cayenne</UnorderedListItem>
            <UnorderedListItem>Cumin</UnorderedListItem>
            <UnorderedListItem>Cardamom</UnorderedListItem>
            <UnorderedListItem>Coriander</UnorderedListItem>
            <UnorderedListItem>Curry powder</UnorderedListItem>
            <UnorderedListItem>Ginger</UnorderedListItem>
            <UnorderedListItem>Nutmeg</UnorderedListItem>
            <UnorderedListItem>Dried parsley (though fresh is always better)</UnorderedListItem>
            <UnorderedListItem>Thyme</UnorderedListItem>
            <UnorderedListItem>Turmeric</UnorderedListItem>
        </UnorderedList>
        <BlogHeading2>
            Sweeteners
        </BlogHeading2>
        <Paragraph>
            Whether you want a sweetener for your coffee or are making a smoothie or a dessert, vegan sweetener options are a must-have. Give these a try:
        </Paragraph>
        <UnorderedList>
            <UnorderedListItem>Coconut sugar</UnorderedListItem>
            <UnorderedListItem>Date sugar (or actual dates)</UnorderedListItem>
            <UnorderedListItem>Granulated sugar</UnorderedListItem>
            <UnorderedListItem>Brown sugar</UnorderedListItem>
            <UnorderedListItem>Maple syrup</UnorderedListItem>
            <UnorderedListItem>Monk fruit</UnorderedListItem>
        </UnorderedList>
        <BlogHeading2>
            Bonus
        </BlogHeading2>
        <Paragraph>
            Lastly, for your vegan food collection to feel complete, consider the following:
        </Paragraph>
        <UnorderedList>
            <UnorderedListItem>Vegetable broth base or bouillon cubes - ideal for making all kinds of soups, sauces, dips, casseroles, pasta dishes, etc.</UnorderedListItem>
            <UnorderedListItem>Nutritional yeast - irreplaceable for seasoning popcorn, salads, pastas, casseroles, for thickening sauces and soups, and providing that cheesy kick whenever you need it.</UnorderedListItem>
            <UnorderedListItem>Mushrooms - must we say more? White button, portobello, shiitake, oyster... Stock your pantry with any kind of delicious mushroom you want.</UnorderedListItem>
            <UnorderedListItem>Wraps - wraps such as tortillas and nori wraps are great for those quick burrito or sushi recipes that will impress everyone in your household.</UnorderedListItem>
        </UnorderedList>
        <Paragraph>
            Here's a <InternalLink to="/plant-based-diet-shopping-list/">plant-based diet shopping list</InternalLink> to help you put together a perfect vegan pantry!
        </Paragraph>
        <BlogHeading2>
            Conclusion
        </BlogHeading2>
        <Paragraph>
            While we offered quite a few choices for you to add to your pantry, we would also like to emphasize that it is important to take into account the personal tastes and eating habits of everyone in your household.
        </Paragraph>
        <Paragraph>
            There is no point in getting a certain spice or grain or legume if you know no one likes to eat it. Building a pantry is a highly individual process, and it is vital that your pantry is tailored to your needs. Otherwise you may end up with more food waste than you’d like.
        </Paragraph>
        <Paragraph>
            That being said, at the same time, you shouldn’t be afraid of experimenting! If you don’t know what something tastes like, or have a vegan recipe you want to try with a new ingredient or two, there is no reason not to give it a go! If you end up not liking it, that’s okay. At least you’ll know for next time.
        </Paragraph>
        <Paragraph>
            Having a well-organized shopping list will be a huge help in setting up your vegan pantry. Check out our <InternalLink to="/#download-section">free grocery list app</InternalLink> to kickstart your vegan journey today.
        </Paragraph>
    </StyledMainContent>
        </BlogPostWrapper>
}

export default BlogPost;